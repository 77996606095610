import React, { useEffect, useState } from "react";
import { cn } from "./lib/utils";
import AnimatedGridPattern from "./components/magicui/animated-grid-pattern";
import banner from './assets/images/banner.png';
import colorado from './assets/images/colorado.png'
import house from './assets/images/house.png'
import dnc from './assets/images/dnc.png'

export function Home() {
    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = () => {
        // Redirect to a specific link
        // window.location.href = 'https://ki6956yqz4i.typeform.com/to/vjBcNZKC'; // Replace with the actual URL
        setIsOpen(true);
    };

    useEffect(() => {
        if (isOpen) {
            // Create script element to embed Typeform
            const script = document.createElement("script");
            script.src = "//embed.typeform.com/next/embed.js";
            script.async = true;
            document.body.appendChild(script);

            // Cleanup function to remove the script when component unmounts or isOpen changes
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [isOpen]);

    return (
        <div className="relative flex flex-col h-screen w-full items-center justify-center overflow-hidden bg-[#191919]">
            <div className={`w-full z-10 whitespace-pre-wrap space-y-16 flex flex-col transform transition-transform duration-500 ease-in-out ${isOpen ? 'scale-75' : 'scale-100'}`}>
                <div className="space-y-6 flex flex-col items-center">
                    <img src={banner} className="h-20 object-contain" />
                    <p className="font-lexend font-light text-md text-neutral-500">Data intelligence for closed ecosystems</p>
                </div>

                {isOpen ? (
                    <>
                        <div data-tf-live="01J839TFDS5SH63JYZ38M1E36X"></div><script src="//embed.typeform.com/next/embed.js"></script>
                    </>
                ) : (
                    <button
                        onClick={handleButtonClick}
                        className="mx-auto font-lexend font-light px-6 py-4 border border-dashed border-neutral-300 rounded-md text-neutral-400 bg-neutral-900 hover:bg-neutral-800 transition ease-in-out duration-75 flex items-center space-x-2"
                    >
                        Apply for a call
                        <div className="arrow ml-2">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                )}

                <div className={`space-y-8 transform transition-transform duration-500 ease-in-out ${isOpen ? 'scale-75' : 'scale-100'}`}>
                    <p className="text-sm font-lexend font-light text-neutral-500">Our customers never miss important insights</p>
                    <div className="inline-flex space-x-6 md:space-x-12 opacity-30">
                        <img src={colorado} className="w-20 md:w-28 object-contain" />
                        <img src={dnc} className="w-20 md:w-28 object-contain" />
                        <img src={house} className="w-20 md:w-28 object-contain" />
                    </div>
                </div>
            </div>

            {/* Grid Pattern */}
            <AnimatedGridPattern
                numSquares={3000}
                maxOpacity={1}
                duration={3}
                repeatDelay={1}
                strokeDasharray={4}
                className="absolute inset-0 transition-all ease-in-out duration-500" // Add transition
            />
            {/* Diamond Shape Gradient Overlay */}
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: 'radial-gradient(ellipse at center, rgba(25, 25, 25, 1) 50%, rgba(25, 25, 25, 0.8) 65%, rgba(25, 25, 25, 0) 100%)',
                }}
            />
        </div>
    );
}